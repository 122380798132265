@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.cursive {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.prose img {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}